import { graphql } from "gatsby"
import React from "react"

import PostBody from "./PostBody"


export const fragment = graphql`
   fragment PostBodyFragment on WpPost_Postslayouts_PostObject_PostBody {
    content {
      ... on WpPost_Postslayouts_PostObject_PostBody_Content_SectionHeading {
        fieldGroupName
        heading
        config {
          alignment
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPost_Postslayouts_PostObject_PostBody_Content_SubHeading {
        fieldGroupName
        heading
        config {
          alignment
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPost_Postslayouts_PostObject_PostBody_Content_TextBlock {
        fieldGroupName
        text
        config {
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPost_Postslayouts_PostObject_PostBody_Content_Image {
        fieldGroupName
        image {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 800
                placeholder: TRACED_SVG
              )
            }
          }
        }
        config {
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPost_Postslayouts_PostObject_PostBody_Content_ActionButtons {
        fieldGroupName
        buttons {
          action
          buttonText
          product {
            ...SimpleProductQuery
            ...VariableProductQuery
          }
        }
        config {
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPost_Postslayouts_PostObject_PostBody_Content_TwoImgBlock {
        fieldGroupName
        twoImgBlock {
          leftImg {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 700
                  height: 394
                  placeholder: TRACED_SVG
                )
              }
            }
          }
          rightImg {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 700
                  height: 394
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
        config {
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
      ... on WpPost_Postslayouts_PostObject_PostBody_Content_Video {
        fieldGroupName
        videoTitle
        videoUrl
        videoPreviewImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 800
                placeholder: TRACED_SVG
              )
            }
          }
        }
        config {
          padding {
            top {
              topMobile
              topTablet
              topDesktop
            }
            bottom {
              bottomMobile
              bottomTablet
              bottomDesktop
            }
          }
        }
      }
    }
  }
`

export const ACFPostBody = ({
  content,
}) => {
  return (
    <section className="post-body">
      <PostBody content={content}  />
    </section>
  )
}
