import React from "react"
import * as style from "./PostBody.module.scss"
import SimpleContent from "../../AcfPageBlocks/SimpleContent/SimpleContent.js"
import { Container } from "react-bootstrap"
const PostBody = ( {content} ) => {
  
  return (
    <>
      <Container>
        <div className={style.articleWrapper}>
          <SimpleContent content={content} />
        </div>      
      </Container>
    </>
  )
}

export default PostBody

