// extracted by mini-css-extract-plugin
export var arrowIcon = "PostBody-module--arrowIcon--de4d3";
export var article = "PostBody-module--article--c39de";
export var articleWrapper = "PostBody-module--articleWrapper--4f387";
export var bgGrey100 = "PostBody-module--bg-grey-100--ef9c4";
export var bgGrey150 = "PostBody-module--bg-grey-150--d1efe";
export var bgGrey200 = "PostBody-module--bg-grey-200--4e674";
export var bgGrey300 = "PostBody-module--bg-grey-300--666e3";
export var bgGrey400 = "PostBody-module--bg-grey-400--12803";
export var bgGrey500 = "PostBody-module--bg-grey-500--6327e";
export var bgGrey600 = "PostBody-module--bg-grey-600--3a327";
export var bgGrey700 = "PostBody-module--bg-grey-700--9795a";
export var bgGrey800 = "PostBody-module--bg-grey-800--6b1c0";
export var bgGrey900 = "PostBody-module--bg-grey-900--78396";
export var blogLinkWrapper = "PostBody-module--blogLinkWrapper--7c2a6";
export var bodyImage = "PostBody-module--bodyImage--6f0c8";
export var componentWrapper = "PostBody-module--componentWrapper--d6cfa";
export var container = "PostBody-module--container--920d6";
export var greyLine = "PostBody-module--greyLine--b4403";
export var imageCaption = "PostBody-module--imageCaption--e422b";
export var postDate = "PostBody-module--postDate--6ae90";
export var postHeader = "PostBody-module--postHeader--29852";
export var postImage = "PostBody-module--postImage--92461";
export var postTitle = "PostBody-module--postTitle--6e4a2";
export var relatedPostsWrapper = "PostBody-module--relatedPostsWrapper--90386";
export var text = "PostBody-module--text--d6a9b";
export var textGrey100 = "PostBody-module--text-grey-100--cd2fc";
export var textGrey150 = "PostBody-module--text-grey-150--59445";
export var textGrey200 = "PostBody-module--text-grey-200--0c27f";
export var textGrey300 = "PostBody-module--text-grey-300--c11c4";
export var textGrey400 = "PostBody-module--text-grey-400--29770";
export var textGrey500 = "PostBody-module--text-grey-500--d97ac";
export var textGrey600 = "PostBody-module--text-grey-600--feb2e";
export var textGrey700 = "PostBody-module--text-grey-700--45631";
export var textGrey800 = "PostBody-module--text-grey-800--288b2";
export var textGrey900 = "PostBody-module--text-grey-900--c2eaf";